.new-ui ol.breadcrumb.breadcrumb-inverse li.breadcrumb-item:last-of-type a {
    font-weight: 400 !important;
}

/*.new-ui ol.breadcrumb {
font-family: UniviaPro !important;
font-weight: 200 !important;
}*/

.breadcrumb > li + li::before {
    content: none !important;
}

.breadcrumb-item + .breadcrumb-item {
    content: none !important;
}

.breadcrumb {
    background-color: transparent !important;
}

    .breadcrumb > li {
        text-shadow: 0 0px 0 #ffffff;
        /*font-family: UniviaPro;*/
    }
/*
.col-md-9 {
    margin-left: 55px
}*/

a {
    text-decoration: none;
}
