
/* css imports */
/*@import '../src/styles/assets/css/imports/tabs.css';*/
/*@import "../src/styles/assets/css/imports/nav-header.css";*/
@import "../src/styles/assets/css/imports/spinner.css";
@import "../src/styles/assets/css/imports/alert.css";
/*@import "../src/styles/assets/css/imports/cards.css";*/
@import url("https://cdn.syncfusion.com/ej2/material.css");

/*@import "../node_modules/@syncfusion/ej2-react-charts/styles/material.css";*/

.loading-indicator {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

    .loading-indicator:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(51,51,51,.8)
    }