@charset "utf-8";
/* CSS Document */

.alert {
    border-radius: 0px;
    padding: 15px 10px;
    color: var(--dark);
}
.alert .close{
    font-size: 24px;
    font-weight: 700;
    color: var(--black);
    opacity: 1;
}
.alert .material-icons {
    line-height: 0px;
    position: relative;
    top: 6px;
}
.alert-info {
    border: 1px var(--primary) solid;
    background: rgba(0, 57, 193, .1);
    border-left: 6px var(--primary) solid;
}
.alert-info .material-icons{
    color: var(--primary);
}

.alert-success {
    border-color: var(--green);
    background: rgba(0, 178, 89, .1);
    border-left: 6px var(--green) solid;
}
.alert-success >input {
    border:1px var(--green) solid;
}
.alert-success .material-icons {
    color: var(--green);
}
.alert-warning {
    border-color: var(--yellow);
    background: rgba(255, 192, 18, .1);
    border-left: 6px var(--yellow) solid;
}

.alert-warning .material-icons {
    color: var(--yellow);
}
.alert-danger {
    border-color: var(--danger);
    background: rgba(236, 28, 36, .1);
    border-left: 6px var(--danger) solid;
}

.alert-danger .material-icons {
    color: var(--danger);
    
}

/*form validatin input message*/
.success-feedback {
    color: var(--green);
}
.invalid-feedback {
    color: var(--danger);
}


